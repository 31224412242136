import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`A bottom sheet component in a mobile app or website typically consists of a sheet of content that slides up from the bottom of the screen, covering a portion of the underlying content. The bottom sheet can be opened and closed by the user, and can contain a variety of elements such as text, images, buttons, and forms. It is often used to display additional information or options related to the main content, and can be implemented as a separate modal or as a part of the main layout.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/guideline/bottom-sheet-component-anatomy.svg",
      "alt": "bottom sheet anatomy guideline"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Header : with a title or controls`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Body : displaying the main content`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Footer : with additional controls`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Overlay : Optionally, a semi-transparent scrim / layer to dim the background`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Handle : Optionally, a gesture or button to swipe and close the sheet`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Bottom sheets are commonly used in mobile apps and websites as a way to present additional information or options to the user. They can be used for a variety of tasks, including:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Displaying additional information: Bottom sheets can be used to display additional information that is related to the main content of the app or website. For example, they can be used to show detailed information about a particular item in a list, or to provide more information about a specific feature or option.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Providing access to options or settings: Bottom sheets can be used to provide access to options or settings that are related to the main content of the app or website. For example, they can be used to allow users to adjust the settings for a specific feature or to change the layout of the app.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Collecting feedback: Bottom sheets can be used to collect feedback from users. For example, they can be used to display a form that users can fill out to provide feedback about the app or website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Showing navigation menu: Bottom sheets can be used to display navigation menu that can be easily accessible from anywhere in the app or website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Showing notifications : Bottom sheet can also be used to show notifications, alerts or any kind of message that requires user attention`}</p>
      </li>
    </ol>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        To provide additional information or options related to the main content
        of the app or website, but don't want to take up too much screen real
        estate.
      </li>
      <li>
        To allow users to access options or settings that are related to the
        main content of the app or website in a convenient and easy-to-use way.
      </li>
      <li>To collect feedback from users about the app or website.</li>
      <li>
        To show navigation menu that can be easily accessible from anywhere in
        the app or website.
      </li>
      <li>
        To show notifications, alerts or any kind of message that requires user
        attention
      </li>
      <li>
        To make use of the space in the bottom of the screen which is not being
        used effectively
      </li>
      <li>
        To make use of the space without blocking the main content of the app or
        website
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        The information or options can be easily presented within the main
        content of the app or website without taking up too much screen real
        estate.
      </li>
      <li>Users don't need to access the options or settings frequently.</li>
      <li>
        The app or website is designed for a small screen and the bottom sheet
        will take too much space and make it difficult to navigate.
      </li>
      <li>
        The main focus of the app or website is on displaying the content and
        additional information or options can be distracting.
      </li>
      <li>
        The bottom sheet will cover important information or call to action that
        should be always visible to the user.
      </li>
      <li>
        The bottom sheet will not be used frequently enough to justify the
        additional development time and effort required to implement it.
      </li>
      <li>
        The app or website is targeting an older audience that may have
        difficulty interacting with the bottom sheet.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Accessibility is an important consideration when designing bottom sheets. Here are some ways to make bottom sheets more accessible:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Provide clear labels: The bottom sheet should have a clear label or title that describes the content or purpose of the sheet. This can help users understand what the sheet is for and what information it contains.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use appropriate contrast: The text and other elements in the bottom sheet should have a high contrast ratio with the background, making it easy for users with low vision to read the content.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make sure it’s keyboard accessible: Bottom sheets should be accessible using a keyboard, so users can navigate through the content and interact with the elements using only a keyboard.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use ARIA roles: Using appropriate ARIA roles can help users using assistive technology understand the purpose of the bottom sheet and the content inside.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide a way to dismiss: The bottom sheet should provide a way for users to dismiss it easily, such as a close button or a key command.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Avoid using bottom sheet for critical information: It’s important to avoid using bottom sheets for critical information or functionality that should always be visible and accessible to the user.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide alternative: It’s also important to provide alternative ways to access the same information or functionality, in case user can not use the bottom sheet due to accessibility reason.`}</p>
      </li>
    </ol>
    <p>{`Overall, bottom sheets can be an effective way to provide additional information or options to users, but it’s important to consider accessibility when designing them to ensure that all users can access and benefit from the information or functionality provided.`}</p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use a visible scrim with bottom sheets to inform users they cannot interact with the rest of the screen." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/guideline/bottom-sheet-component-guideline-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Don’t use an invisible scrim for bottom sheets. This can mislead users about their ability to interact with the rest of the screen." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/guideline/bottom-sheet-component-guideline-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Bottom Sheet :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Keep it relevant: The content in the bottom sheet should be relevant to the task or information the user is currently viewing or interacting with.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Keep it brief: Bottom sheets are typically used to present supplementary information, so the content should be brief and to the point.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use clear and consistent formatting: The content should be easy to read and understand, with a clear hierarchy of information and consistent formatting.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use visual aids: Including images, icons, and other visual aids can help to break up the text and make the content more engaging.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make it actionable: Bottom sheets are often used to present options or actions, so make sure the content includes clear calls to action.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use white space: Keep the sheet minimalistic by using white space to separate content and make it more legible.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Test with users: It’s always a good idea to test your bottom sheet content with users to see how it performs in a real-world context and make adjustments as necessary.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      